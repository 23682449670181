<template>
  <v-row>
    <v-col cols="12" md="6">
      <lookup-dominio label="Mês"
                      v-model="value.mesAno"
                      :type="lookups.mesAno"
                      :hidden-clear="false"
                      :rules="[
                        this.$validators.string.required,
                      ]"/>
    </v-col>
    <v-col cols="12" md="6">
      <lookup-dominio label="Status Matrícula"
                      v-model="value.status"
                      :type="lookups.status"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" md="6">
      <search-box
          :hidden-clear="false"
          label="Local"
          popup-label="Selecione um local"
          :id.sync="value.idLocal"
          :descricao.sync="value.descricao_idLocal"
          :value="local"
          :loader-fn="loaderLocal"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum local encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="6">
      <search-box
          :hidden-clear="false"
          label="Curso"
          popup-label="Selecione um curso"
          :id.sync="value.idCurso"
          :descricao.sync="value.descricao_idCurso"
          :value="curso"
          :loader-fn="loaderCurso"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum curso encontrado
          </p>
        </template>

      </search-box>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllCursos} from "../../../../api/cursos";
import {findAll as findAllLocal} from "../../../../api/locais";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";

export default {
  name: "RelatorioAniversariantesForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
        mesAno: ItemDeDominio.ARR_MES,
      },
      loaderCurso(page, search) {
        return findAllCursos(page, {
          descricao: search
        })
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    curso() {
      if (this.value.idCurso == null) return null;
      return {
        id: this.value.idCurso,
        descricao: this.value.descricao_idCurso
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>